import * as React from "react";
import Rating from "@mui/material/Rating";
import StarRoundedIcon from "@mui/icons-material/StarRounded";

export default function CustomizedRating({ value, onChange }) {
  return (
    <Rating
      value={value}
      onChange={onChange}
      precision={0.5}
      icon={
        <StarRoundedIcon
          style={{ width: "45px", height: "45px", color: "#fcd11e" }}
          fontSize="inherit"
        />
      }
      emptyIcon={
        <StarRoundedIcon
          style={{ width: "45px", height: "45px", color: "lightgrey" }}
          fontSize="inherit"
        />
      }
    />
  );
}
