import React, { useCallback, useEffect, useState } from "react";
import CustomizedRating from "./components/CustomizedRating";
import styles from "./styles/App.module.scss";

function App() {
  const [rating, setRating] = useState(0);
  const [reviewContent, setReviewContent] = useState("");
  const [isFormVisible, setIsFormVisible] = useState(false);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [isConsent, setIsConsent] = useState(false);
  const [positiveReviewCallout, setPositiveReviewCallout] = useState(false);
  const [defaultReviewCallout, setDefaultReviewCallout] = useState(false);
  const [naverPlaceCode, setNaverPlaceCode] = useState("");

  const [charCount, setCharCount] = useState(0);

  const handleReviewChange = useCallback((event) => {
    const text = event.target.value;
    setReviewContent(text);
    setCharCount(text.length);
  }, []);

  const handleRatingChange = (event, newRating) => {
    if (newRating !== null) {
      setRating(newRating);
      setIsFormVisible(true);
    }
  };

  const getReviewMessage = (rating) => {
    if (rating <= 1) return "별로예요";
    if (rating <= 2) return "그저그래요";
    if (rating <= 3) return "괜찮아요";
    if (rating <= 4) return "좋아요";
    return "최고예요";
  };

  const handleIsConsentChange = (event) => {
    setIsConsent(event.target.checked);
  };

  const postReviewData = async (data) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/chart/api/hospital-review`,
        {
          method: "POST",
          body: JSON.stringify(data),
          headers: {
            "Content-Type": "application/json",
            "x-api-key": process.env.REACT_APP_API_KEY,
          },
        }
      );

      if (!response.ok) {
        throw new Error("네트워크 응답이 올바르지 않습니다");
      }

      return await response.json();
    } catch (error) {
      throw error;
    }
  };

  const [hospitalData, setHospitalData] = useState({ name: "" });
  const fetchHospitalName = async () => {
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let crmKey = params.get("crm_key");
    let chartType = params.get("chart_type") || "initial";
    try {
      const data = await fetch(
        `${process.env.REACT_APP_API_URL}/chart/api/hospital-review?chart_type=${chartType}&chart_key=${crmKey}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "x-api-key": process.env.REACT_APP_API_KEY,
          },
        }
      );
      const json = await data.json();
      setHospitalData(json);
    } catch (e) {
      setHospitalData({ name: "동물병원" });
    }
  };

  useEffect(() => {
    fetchHospitalName();
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!reviewContent.trim()) {
      setAlertMessage("리뷰를 작성해주세요.");
      return;
    }
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let crmKey = params.get("crm_key");
    let chartType = params.get("chart_type") || "initial";
    if (!crmKey) {
      setAlertMessage(
        "잘못된 접근입니다. 리뷰 작성 경로를 다시 한번 확인해주세요."
      );
      return;
    }

    let data = {
      chart_type: chartType,
      chart_key: crmKey,
      rating: rating,
      content: reviewContent,
      use_consent: isConsent,
    };

    try {
      const res = await postReviewData(data);
      setNaverPlaceCode(res.naver_place_code);
      setPositiveReviewCallout(res.positive_review_callout);
      setDefaultReviewCallout(res.default_review_callout);
      setIsFormVisible(false);
      setIsFormSubmitted(true);
    } catch (error) {
      console.error(error);
      setAlertMessage("리뷰 저장에 실패했습니다. 다시 시도해주세요.");
    }
  };

  return (
    <>
      <form name="reviewForm" id="reviewForm" onSubmit={handleSubmit}>
        {hospitalData?.name?.length > 15 ? (
          <>
            <div className={styles.titleText}>{hospitalData.name}</div>
            <div className={styles.titleText}>만족도</div>
          </>
        ) : (
          <div className={styles.titleText}>{hospitalData.name + " 만족도"}</div>
        )}
        {!isFormSubmitted && (
          <div className={styles.ratingContainer}>
            <CustomizedRating value={rating} onChange={handleRatingChange} />
            <div>
              {rating.toFixed(1)} {getReviewMessage(rating)}
            </div>
          </div>
        )}
        {isFormVisible && (
          <div className={styles.contentContainer}>
            <textarea
              placeholder="저희 서비스 중 마음에 드신 점 혹은 아쉬웠던 점을 자세히 작성해 주세요. (세심한 설명, 전문성, 대기시간 문제, 설명 미흡 등)"
              name="reviewContent"
              onChange={handleReviewChange}
              value={reviewContent}
              maxLength={400}
            />
            <div className={styles.tooltip}>{charCount}/400</div>
            <div className={styles.alertMessage}>{alertMessage}</div>
            <div className={styles.checkboxwrap}>
              <input
                type="checkbox"
                id="consent"
                checked={isConsent}
                onChange={handleIsConsentChange}
              />
              <label htmlFor="consent" className={styles.checkLabel}>
                (선택) 만족도 리뷰 공개 및 활용 동의 - 보호자 & 반려동물 이름은
                블라인드 처리됩니다.
              </label>
            </div>
            <br />
            <button type="submit" className={styles.formBtn}>
              제출
            </button>
          </div>
        )}
        {isFormSubmitted && rating < 4 && (
          <div className={styles.submitMessage}>
            {defaultReviewCallout === "" || defaultReviewCallout === null
              ? "성공적으로 제출되었습니다.\n" +
                "보호자님의 만족도를 높이기 위해 최선을 다하겠습니다. 감사합니다."
              : defaultReviewCallout}
          </div>
        )}
        {isFormSubmitted && rating >= 4 && (
          <div className={styles.submitMessage}>
            {positiveReviewCallout === "" || positiveReviewCallout === null
              ? "성공적으로 제출되었습니다.\n" +
                getReviewMessage(rating) +
                "를 선택해주셨군요. 긍정적인 평가에 감사드립니다.\n" +
                "앞으로도 더 나은 의료서비스를 위해 최선을 다하겠습니다. 감사합니다."
              : positiveReviewCallout.replace(
                  "{rating_text}",
                  getReviewMessage(rating)
                )}
            <br />
            {naverPlaceCode && (
              <p>
                <button
                  type="button"
                  className={styles.formBtn}
                  onClick={() =>
                    window.open(
                      `https://m.place.naver.com/place/${naverPlaceCode}/review/visitor?entry=pll`,
                      "_blank",
                      "noopener,noreferrer"
                    )
                  }
                >
                  네이버 리뷰 작성하기
                </button>
              </p>
            )}
          </div>
        )}
      </form>
    </>
  );
}

export default App;
